// 关于我们
<template>
  <div class="about-wrapper">
    <Bigimg
      title="关于我们"
      subTitle="我们专注于移动互联网与系统集成业务，从策略到支持，持续为用户创造价值"
      :imgUrl="require('@/assets/images/spfw.jpg')"
    ></Bigimg>
    <div class="about ">
      <Headline class="mt-8" title="企业简介" subtitle="Company Profile" />
      <p class="about-desc wrap-v1">
        蓝亚（深圳）信息技术有限公司 （Blue Asia(Shenzhen) Information
        Technology Co., Ltd.），于2016年8月份正式注册成立，
        我司是互联网综合服务提供商，目前 团队分布在深圳和北京，团队成员有
        40人，业务方向包含行业平台开发、 智能电视大屏业务平台开发、智能物
        联网设备运营管理平台研发、大数据 分析能力系统研发、容器化云服务平
        台等。现有已取得23项软件著作权认 证、国家级高新企业认证审核中等。
      </p>
      <Headline class="mt-8" title="企业发展" subtitle="History" />
      <!-- <img src="../assets/images/28.jpg" class="about-img wrap-v1" /> -->
      <div class="years wrap-v1">
        <div class="year">2016</div>
        <div class="year">2017</div>
        <div class="year">2018</div>
        <div class="year">2019</div>
        <div class="year">2020</div>
      </div>
      <div class="ups wrap-v1">
        <div class="up"></div>
        <div class="up"></div>
        <div class="up"></div>
        <div class="up"></div>
        <div class="up"></div>
      </div>
      <div class="texts wrap-v1">
        <div class="text">
          <div class="one">1、企业注册，成立北京研发项目组</div>
          <div class="two">2、面向智能大屏增值业务研发</div>
        </div>
        <div class="text">
          <div class="one">1、可视化数据分析系统1.0版本发布</div>
          <div class="two">2、与厦门一品威客达成战略合作</div>
        </div>
        <div class="text">
          <div class="one">1、行业应用解决方案发布，交付</div>
          <div class="two">2、5G大数据专项预研项目组成立</div>
        </div>
        <div class="text">
          <div class="one">1、针对工业物联网进行创新方案预研</div>
          <div class="two">2、落地设备管理、预警、控制项目等</div>
        </div>
        <div class="text">
          <div class="one">1、为北京传媒公司提供全方位技术支持</div>
          <div class="two">2、工业物联网创新方案2.0版本研发</div>
        </div>
      </div>
      <div class="years wrap-v1">
        <div class="text">创新</div>
        <div class="text">求实</div>
        <div class="text">奋进</div>
      </div>
      <Headline class="mt-8" title="技术领域" subtitle="Technosphere" />
      <img src="../assets/images/29.jpg" class="about-img wrap-v1" />
      <contactForm />
    </div>
  </div>
</template>
<script>
import contactForm from "@/components/contact-form.vue";
import Headline from "@/components/headline.vue";
import Bigimg from "@/components/bigimg.vue";

export default {
  components: { contactForm, Headline, Bigimg }
};
</script>
<style scoped lang="scss">
.mt-8 {
  margin-top: 80px;
}
.about-wrapper {
  .about {
    padding: 0 20px 150px;

    .title {
      margin-top: 80px;
      font-size: 50px;
      color: #333333;
      line-height: 70px;
    }
    .about-desc {
      margin-top: 50px;
      font-size: 20px;
      color: #333333;
      line-height: 40px;
    }
    .years {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px;
      margin-top: 75px;
      background: #007bb9;
      font-size: 30px;
      color: #ffffff;
      line-height: 75px;
      .year {
        width: 240px;
        height: 75px;
        border-radius: 2px;
      }
      .text {
        margin: 0 62px;
      }
      .year:nth-child(2),
      .year:nth-child(4) {
        background: #9bd3ef;
      }
    }
    .ups {
      margin-top: 18px;
      margin-bottom: 21px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .up {
        height: 112px;
        width: 23px;
        background-image: url("../assets/images/up.png");
      }
    }
    .texts {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 20px;
      color: #333333;
      line-height: 34px;
      text-align: left;
      .text {
        padding: 0 16px;
        .one {
          margin-bottom: 16px;
        }
      }
    }
    .about-img {
      margin-top: 75px;
      display: block;
      width: 100%;
    }
    .about-contact {
      margin-top: 45px;
      padding: 30px 0;
      border: 1px solid #d5d5d5;
      border-radius: 2px;

      font-size: 30px;
      color: #333333;
      line-height: 42px;
      display: flex;
      justify-content: space-around;
    }
  }
  .ml-2 {
    margin-left: 20px;
  }
}
</style>
